


































import { translations, eventHandler } from "@/mixins";
import {
  Location,
  Base,
  SettingsFacilities,
  LocationFacilities,
} from "@/services/SOLO";
import { Facility as FacilityModel } from "@/models";
import Translations from "./components/Translations.vue";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { LoadingPanel } from "@/components";

interface ojbKey {
  [x: string]: string;
}

interface newLocation {
  id: string;
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "delivery-charge": string;
  "delivery-integration-id": string;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
  "custom-fields": Array<any>;
}

@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    LocationInfoForm: () => import("./components/LocationInfoForm.vue"),
    LocationFacilities: () => import("./components/LocationFacilities.vue"),
    LocationCustomFields: () => import("./components/LocationCustomFields.vue"),
  },
  mixins: [translations, eventHandler],
})
export default class LocationUpdate extends Vue {
  @Prop() locationId!: string;
  private locationLoaded: Boolean = false;
  private arrayLang: Array<string> = ["en-us", "ar-sa"];
  private langNum: number = 0;
  private gmapModal: Boolean = false;
  private loadingFacilities: Boolean = false;
  private locationFacilities: Array<FacilityModel> = [];
  private availableFacilities: Array<FacilityModel> = [];
  private location: newLocation = {
    id: "",
    name: {},
    status: "",
    telephone: "",
    pos: "",
    email: "",
    country: "",
    lat: "",
    long: "",
    "delivery-integration-id": "",
    "delivery-enabled": true,
    "pickup-enabled": true,
    "curbside-enabled": true,
    "delivery-charge": "",
    line1: {},
    "promised-time-delta": {
      delivery: "",
      pickup: "",
    },
    "opening-hours": [],
    "custom-fields": [],
  };

  translate!: Function;

  private openingHours: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  constructor() {
    super();
    for (let i = 0; i < this.arrayLang.length; i++) {
      this.location.name[this.arrayLang[i]] = "";
      this.location.line1[this.arrayLang[i]] = "";
    }

    this.location["opening-hours"] = this.openingHours;
  }

  created() {
    this.arrayLang.map((lang: string) => {
      this.fetchLocation(this.locationId, lang);
    });

    this.fetchAvailableFacilities();
    this.fetchLocationFacilities();
  }

  private onLocationUpdated(location: Location) {
    this.$emit("location:updated", location);
  }

  private onLocationDeleted(location: Location) {
    this.$emit("location:deleted", location);
  }

  private fetchLocation(locationId: string, lang: string) {
    Location.fetchLocation(locationId, lang).then((response: any) => {
      let res = response.data.data.attributes;
      if (!this.langNum) {
        this.location.id = response.data.data.id;
        this.location.status = res.status;
        this.location.telephone = res.telephone;
        this.location.pos = res.pos;
        this.location.email = res.email;
        this.location.country = res.country;
        this.location.lat = res.lat;
        this.location.long = res.long;
        this.location["delivery-enabled"] = res["delivery-enabled"]
          ? true
          : false;
        this.location["pickup-enabled"] = res["pickup-enabled"] ? true : false;
        this.location["curbside-enabled"] = res["curbside-enabled"]
          ? true
          : false;
        this.location["delivery-charge"] = res["delivery-charge"];
        this.location["delivery-integration-id"] = res["delivery-integration-id"];
        this.location["custom-fields"] = res["custom-fields"];
        this.location["promised-time-delta"].delivery =
          res["promised-time-delta"].delivery;
        this.location["promised-time-delta"].pickup =
          res["promised-time-delta"].pickup;
        res["opening-hours"].map((hours: { [x: string]: any }, index: any) => {
          this.location["opening-hours"][index].day = hours.day;
          this.location["opening-hours"][index].open = moment(
            `${hours.open}`,
            "hh:mm"
          ).format("hh:mm A");
          this.location["opening-hours"][index].closed = moment(
            `${hours.closed}`,
            "hh:mm"
          ).format("hh:mm A");
        });
      }
      this.location.name[lang] = res.name;
      this.location.line1[lang] = res.line1;
      this.langNum++;
      this.locationLoaded = true;
    });
  }

  private async fetchLocationFacilities() {
    this.loadingFacilities = true;
    const response = await LocationFacilities.all(this.locationId);
    this.locationFacilities = response.data.data;
    this.loadingFacilities = false;
  }

  private async fetchAvailableFacilities() {
    let response = await SettingsFacilities.all();
    this.availableFacilities = response.data.data;
  }

  private updateLocationFacilities(data: Array<FacilityModel>) {
    this.locationFacilities = data;
  }
}
